import React, { useEffect } from 'react';
import { isBrowser } from '../utils/isBrowser';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Faq from '../components/faqNew';

import { BackgroundWrapper } from '../styledComponents';

const faqContetnt = [
  {
    title: 'Как я получу автомобиль?',
    text:
      'После бронирования и оплаты поездки вы сможете забрать машину возле дома владельца или заказать доставку. Собственник подготовит машину и лично передаст вам ключи без залогов и бумажных договоров. Телефон собственника будет доступен сразу после бронирования',
  },
  {
    title: 'Когда и как происходит оплата?',
    text:
      'Оплата поездки осуществляется банковской картой. При бронировании мы «заморозим» деньги и спишем их только после получения подтверждения аренды от владельца.',
  },
  {
    title: 'Что если собственник отменит аренду?',
    text:
      'В случае, если собственник откажет в бронировании в момент получения вашего запроса, деньги моментально вернутся на вашу карту, а служба поддержки поможет подобрать другой автомобиль',
  },
  {
    title: 'Может ли собственник не отдать мне автомобиль после бронирования?',
    text:
      'После подтверждения аренды владелец обязуется передать вам автомобиль в назначенный срок. Собственники получают выплату за аренду только после ее завершения.',
  },
  {
    title: 'Как мне узнать условия аренды собственника?',
    text: 'Все условия владельца доступны на странице информации об автомобиле.',
  },
  {
    title: 'Нужно ли подписывать бумажный договор?',
    text:
      'Все договоры подписываются онлайн. Ознакомиться с правилами аренды и другими юридическими документами можно в ',
    link: 'Центре Помощи',
    href: 'https://help.getarent.ru',
  },
  {
    title: 'Какие условия страхования поездки?',
    text:
      'Ваша поездка застрахована по ОСАГО и КАСКО мультидрайв на полную стоимость автомобиля от повреждений и угона. Если ДТП произойдет не по вашей вине, вам не придется ничего выплачивать. В случае, когда авария произойдет по вашей вине (или по обоюдной), ваша ответственность за причинённый ущерб арендованному автомобилю ограничена суммой 20 000 рублей. В случае грубого нарушения договора (передача управления другому водителю, езда в нетрезвом виде, использование автомобиля в коммерческих целях и другое) стоимость ущерба выплачивается полностью.',
  },
  {
    title: 'Я могу связаться с собственником до аренды?',
    text:
      'После бронирования (перед подтверждением аренды владельцем) вам будет доступен его контактный телефон.',
  },
];

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isBrowser]);

  return (
    <Layout>
      <SEO
        title="Ответы на частые вопросы"
        description="Найдите ответы на другие вопросы и узнайте больше о возможностях на сервисе"
      />
      <BackgroundWrapper>
        <Faq
          content={faqContetnt}
          link="https://help.getarent.ru?utm_source=car-rental-income&utm_medium=pereiti-centr-pomoschi"
          title="Ответы на частые вопросы"
          text="Найдите ответы на другие вопросы и узнайте больше о возможностях на сервисе"
        />
      </BackgroundWrapper>
    </Layout>
  );
};
